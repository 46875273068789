/*
 * @Author: wuhuiwang
 * @Date: 2024-08-01 14:12:13
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-08-01 16:30:51
 */
export default {
    bind(el, { value, arg, modifiers }, vnode) {
        const input = el.getElementsByTagName('input')[0];
        const handleEvent = (event, type) => {
            const elementContent = { ...value };
            if (['Input', 'Focus', 'Blur'].includes(type)) {
                elementContent.inputVal = event?.target?.value;
            }
            const eventName = `${vnode.context.$route.name}_${arg}Input_`;
            vnode.context.$app.uploadClickEvent(`${eventName}${type}`, elementContent);
        };
        if (modifiers.click) {
            input.addEventListener('click', event => handleEvent(event, 'Click'));
        }
        if (modifiers.focus) {
            input.addEventListener('focus', event => handleEvent(event, 'Focus'));
        }
        if (modifiers.blur) {
            input.addEventListener('blur', event => handleEvent(event, 'Blur'));
        }
        if (modifiers.input) {
            input.addEventListener('input', event => handleEvent(event, 'Input'));
        }
    }
};
