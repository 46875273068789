/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-10-Mo 07:53:10
 */

import Vue from 'vue';
import VueRouter from 'vue-router';

import {createVueRouter} from 'ssr-common/vue/router';

import routes from './routes';

Vue.use(VueRouter);

export const createRouter = () => createVueRouter({routes});
