/*
 * @Author: chengyuzhang
 * @Date: 2021-01-25 18:40:48
 * @Last Modified by: bowang
 * @Last Modified time: 2023-04-21 11:49:45
 */
import EventUtil from 'easycash/common/util/event';
export default (BRAND) => ({
    bind(el, { value }, vnode) {
        if (!value) {
            return;
        }
        if (value.type === 'input') {
            const { tag, businessEvent, uploadValue, ...otherParams } = value;
            const focusParams = {
                tag: `${tag}InputFocus`,
                businessEvent: `${businessEvent}InputFocus`,
                elementType: 'Button',
                eventType: 'C',
                ...otherParams
            };
            const blurParams = {
                tag: `${tag}InputBlur`,
                businessEvent: `${businessEvent}InputBlur`,
                elementType: 'Button',
                eventType: 'C',
                ...otherParams
            };
            el.addEventListener('focus', () => {
                EventUtil.uploadEvent(focusParams || {}, vnode.context.$route, BRAND);
            });
            el.addEventListener('blur', (event) => {
                EventUtil.uploadEvent((uploadValue ? {
                    ...blurParams,
                    ...otherParams,
                    elementContent: {
                        ...otherParams.elementContent,
                        [typeof uploadValue === 'string' ? uploadValue : 'inputVal']: event?.target?.value
                    }
                } : blurParams) || {}, vnode.context.$route, BRAND);
            });
        }
        else {
            el.addEventListener('click', () => {
                EventUtil.uploadEvent(value || {}, vnode.context.$route, BRAND);
            });
        }
    }
});
