/**
 * @Author: xiaodongyu
 * @Date: 2023-07-28 12:08:36
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-06-20 11:30:17
 */
import { BrowserTracing, init, vueRouterInstrumentation } from '@sentry/vue';
import { STAGE } from '@shared/common/constant/stage';
export * from '@sentry/vue';
const isProd = /prod/i.test(STAGE);
const tracesSampleRate = isProd ? 0.01 : 1.0;
export function initSentry({ router, throwTestError, beforeSend, ...rest }) {
    try {
        // only init sentry in test/feat/prod env
        if (!/test|feat|prod/i.test(STAGE)) {
            return;
        }
        const filters = [
            filterNoneErrorRejection,
            beforeSend
        ].filter(Boolean);
        init({
            environment: STAGE,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: vueRouterInstrumentation(router)
                })
            ],
            // enable sentry debug mode in none prod env
            debug: !isProd,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate,
            release: window.__SENTRY_PROJECT_RELEASE_NAME__ || undefined,
            beforeSend: async (event, hint) => {
                let result = event;
                for (const filter of filters) {
                    result = await filter?.(result, hint);
                    if (!result)
                        break;
                }
                return result;
            },
            ...rest
        });
    }
    catch (err) {
        // ignore
    }
    if (throwTestError) {
        setTimeout(() => {
            throw new Error(typeof throwTestError === 'string' ? throwTestError : 'sentry test');
        }, 3e3);
    }
}
/**
 * filter Modal close rejection, axios cancel rejection, etc.
 */
const filterNoneErrorRejection = (event, hint) => {
    if (hint.originalException instanceof Error) {
        return event;
    }
    return null;
};
