/*
 * @Author: chengyuzhang
 * @Date: 2021-01-26 15:29:36
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-08-We 05:47:54
 */
const Brand = {
    'investaja': 'IDN',
    'easycash': 'IDN',
    'credmex': 'MEX',
    'mabilis-cash': 'PHI',
    'easypln': 'POL',
    'th-fincash': 'THA',
    'mayacash': 'MEX',
    'fintopia-easypln': 'POL'
};
export default Brand;
