/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-10-Mo 07:52:49
 */

import Vue from 'vue';

import http from 'ssr-common/vue/vue-http';

import App from './app/app';
import {createI18n} from './common/i18n';
import {createRouter} from './common/router';
import {createStore} from './common/store';

export const createApp = context => {
    const i18n = createI18n(context);
    const router = createRouter();
    const store = createStore();

    http.defaults.headers.common.build = context.build || '21700';

    const app = new Vue({
        i18n,
        router,
        store,
        render: h => h(App) // eslint-disable-line id-length
    });

    return {app, router, store};
};
