/*
 * @Author: Quxiaoya
 * @Date: 2019-06-25 15:36:52
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2022-10-11 11:37:12
 */

import {getVersionCode, isIOS, isYqdCredMex, isAndroid} from 'ssr-common/util/webview/ua';

export default context => ({

    data() {
        let ua;
        if (context) {
            ua = context.userAgent;
        } else if (this.$isServer && this.$ssrContext) {
            ua = this.$ssrContext.userAgent;
        } else if (!this.$isServer) {
            ua = window.navigator.userAgent;
        }

        return {
            ua,
            versionCode: getVersionCode(ua),
            isIOS: isIOS(ua),
            isYqdCredMex: isYqdCredMex(ua),
            isAndroid: isAndroid(ua)
        };
    }

});
