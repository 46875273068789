/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-10-Mo 07:53:08
 */

import Vue from 'vue';
import Vuex, {Store} from 'vuex';

import baseData from './module/base-data';

Vue.use(Vuex);

export const createStore = () => new Store({
    state: {},
    modules: {
        baseData
    }
});
