/*
 * @Author: zhaoyang
 * @Date: 2023-12-20 14:28:55
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-12-22 14:37:45
 */
import { setUser } from '@sentry/vue';
import ua from './webview/ua-parsed';
import WebviewInterface from './webview/yqg-webview-interface';
export const setSentryUser = () => {
    try {
        if (!ua.isWebview)
            return;
        WebviewInterface.callHandler('getHashedUserId', '', (hashedUserId) => {
            setUser({ id: hashedUserId });
        });
    }
    catch (error) {
        // ignore
    }
};
