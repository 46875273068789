/*
 * @Author: chengyuzhang
 * @Date: 2020-09-28 11:13:59
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-11-14 14:11:23
 */

export default {
    namespaced: true,

    state: () => ({
        primaryColor: '#5338FF',
        brand: 'credmex'
    })
};
