/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-10-Mo 08:07:19
 */

export default {

    install(Vue) {
        Vue.component('yqg-md-preview', () => import('ssr-common/vue/component/yqg-md-preview'));
        Vue.component('yqg-pdf-viewer', () => import('ssr-common/vue/component/yqg-pdf-viewer'));
        Vue.component('ec-image', () => import('ssr-common/vue/component/ec-image'));
        Vue.component('cm-credit', () => import('./cm-credit/index.vue'));
    }

};
