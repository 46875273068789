/*
 * @Author: zhaoyang
 * @Date: 2023-06-13 17:21:00
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-08-15 18:38:04
 */

import {parse} from 'ssr-common/util/webview/ua';
import parseUa from 'ssr-common/vue/mixin/parse-ua';
import http from 'ssr-common/vue/vue-http';

export default {
    mixins: [parseUa()],

    created() {
        const {common} = http.defaults.headers;

        const ua = parse(this.ua);

        if (ua.isAndroid) {
            common.PlatformType = 'ANDROID';
        } else if (ua.isIOS) {
            common.PlatformType = 'IOS';
        } else {
            common.PlatformType = 'WEB';
        }
    }
};
