/*
 * @Author: ruiwang
 * @Date 2023-03-17 11:22:04
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-10-18 14:04:44
 */

import {apiErrorReportToSentry} from '@shared/client/util/sentry-error';

import http from 'ssr-common/vue/vue-http';

export default {
    data() {
        return {
            reqCount: 0,
            requestInterceptor: null,
            responseInterceptor: null
        };
    },

    beforeMount() {
        this.initHttpInterceptors();
    },

    beforeDestroy() {
        this.clearClientInterceptors();
    },

    methods: {
        $showLoading() {
            if (this.$isServer) return;
            this.reqCount += 1;
        },

        $hideLoading() {
            this.reqCount = Math.max(0, this.reqCount - 1);
        },

        initHttpInterceptors() {
            if (this.requestInterceptor !== null) return;

            this.requestInterceptor = http.interceptors.request.use(
                config => {
                    const {hideLoading} = config;
                    if (!hideLoading) {
                        this.$showLoading();
                    }

                    config.deviceToken = config?.headers?.common?.['YQG-PLATFORM-DEVICE-TOKEN'];

                    config.cookies = document?.cookie;

                    return config;
                },
                err => {
                    this.$hideLoading();

                    return Promise.reject(err);
                }
            );

            this.responseInterceptor = http.interceptors.response.use(
                res => {
                    const {config: {hideLoading}} = res;
                    if (!hideLoading) {
                        this.$hideLoading();
                    }

                    return Promise.resolve(res);
                },
                error => {
                    const {
                        status,
                        data: {status: {code, detail} = {}} = {},
                        config: {hideLoading, hideToast, getContainer = 'body'} = {},
                        response: {status: errorStatus, errorText} = {},
                        config
                    } = error;

                    if (!hideLoading) {
                        this.$hideLoading();
                    }

                    if (detail && !hideToast) {
                        this.$toast.fail({
                            message: detail,
                            getContainer
                        });
                    }

                    apiErrorReportToSentry(config, {
                        httpCode: status || errorStatus,
                        code,
                        detail: detail || errorText
                    });

                    return Promise.reject(error.response || error);
                }
            );
        },

        clearClientInterceptors() {
            http.interceptors.request.eject(this.requestInterceptor);
            http.interceptors.response.eject(this.responseInterceptor);
        }
    }
};
