/*
 * @Author: chengyuzhang
 * @Date: 2022-06-10 16:55:58
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-07-24 18:26:44
 */
import { isServer } from 'ssr-common/constant/config';
const genKey = () => {
    let token = '';
    const codeAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < 8; i += 1) {
        token += codeAlphabet[Math.floor(Math.random() * 26)];
    }
    return `${token}-${Date.now()}`;
};
export const getKey = () => {
    if (isServer())
        return '';
    let userKey = window.localStorage.getItem('userKey');
    if (!userKey) {
        userKey = genKey();
        window.localStorage.setItem('userKey', userKey);
    }
    return userKey;
};
export default genKey;
