/*
 * @Author: zhaoyang
 * @Date: 2024-07-18 12:28:44
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-10-Th 12:11:17
 */
import sensors from 'sa-sdk-javascript';
// import pageleave from 'sa-sdk-javascript/dist/web/plugin/pageleave/index.es6';
// import pageload from 'sa-sdk-javascript/dist/web/plugin/pageload/index.es6';
import Exposure from 'sa-sdk-javascript/dist/web/plugin/exposure/index.es6';
import { getQueryParams } from '@shared/client/util/url';
import { STAGE } from '@shared/common/constant/stage';
const isProd = /prod/i.test(STAGE);
// 数据接收地址按照国家区分，每个国家有一个生产和一个非生产数据接收地址
const countryServerUrlMap = {
    indo: {
        default: 'https://datasink.easycash.id/sa?project=default',
        prod: 'https://datasink.easycash.id/sa?project=production'
    },
    mex: {
        default: 'https://datasink-mex.fintopia.tech/sa?project=default',
        prod: 'https://datasink-mex.mxcred.com/sa?project=production'
    }
};
export const initSensors = ({ country, platformName, businessName, userId = '', useAppJsBridge = false, needAutoTrack = true, needPageLoad = true }, sensorsConfig = {}) => {
    sensors.init({
        server_url: countryServerUrlMap[country][isProd ? 'prod' : 'default'],
        is_track_single_page: true,
        use_client_time: true,
        send_type: 'image',
        show_log: !isProd,
        app_js_bridge: useAppJsBridge,
        heatmap: {
            // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            clickmap: 'default',
            // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
            scroll_notice_map: 'not_collect',
            // 全埋点去除vconsole
            collect_element(element) {
                return !element.className.includes('vc-');
            },
        },
        ...sensorsConfig
    });
    // 全局公参
    sensors.registerPage({
        fin_user_id: userId,
        is_login: !!userId,
        platform_name: platformName,
        business_name: businessName,
    });
    if (userId)
        sensors.login(userId);
    const elementContent = getQueryParams(location.href);
    if (needAutoTrack) {
        sensors.quick('autoTrack', elementContent); // 用于采集 $pageview 事件
    }
    if (needPageLoad) {
        sensors.use('PageLoad', { max_duration: 10 * 60 }); // 10分钟 用于采集 WebPageLoad 事件
    }
    sensors.use('PageLeave', {
        custom_props: elementContent
    }); // 用于采集 WebPageLeave 事件
    // 曝光
    sensors.use(Exposure, {
        // 可见比例
        area_rate: 0.5,
        // 停留时长 单位秒
        stay_duration: 0.5,
        // 是否重复曝光
        repeated: false
    });
    return sensors;
};
export default sensors;
