/*
 * @Author: zhaoyang
 * @Date: 2024-10-09 11:03:08
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-10-09 11:08:54
 */
export const getQueryParams = (url) => {
    try {
        // 创建一个 URL 对象
        const urlObj = new URL(url);
        // 创建一个 URLSearchParams 实例
        const params = new URLSearchParams(urlObj.search);
        // 初始化一个空对象
        const queryParams = {};
        // 遍历所有的查询参数
        for (const [key, value] of params.entries()) {
            queryParams[key] = value; // 将每个键值对放入对象中
        }
        return queryParams; // 返回查询参数对象
    }
    catch (error) {
        return {}; // 如果出错，返回一个空对象
    }
};
