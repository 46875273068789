/*
 * @Author: wuhuiwang 
 * @Date: 2024-10-Tu 07:33:28 
 * @Last Modified by:   wuhuiwang 
 * @Last Modified time: 2024-10-Tu 07:33:28 
 */

import {
    numberWithCommas,
    numberWithCommasFixed2
} from 'ssr-common/util/string';

export default {
    install(Vue) {
        Vue.filter('yqgNumCommas', numberWithCommas);
        Vue.filter('yqgNumCommasFixed', numberWithCommasFixed2);
    }
};
