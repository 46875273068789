/*
 * @Author: chengyuzhang
 * @Date: 2021-01-26 11:51:09
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2021-01-27 16:21:48
 */

import Resource from '@yqg/resource';

const api = {
    uploadEvents: {
        url: '/event/uploadEvents/v2',
        method: 'post'
    }
};

export default Resource.create(api);
