/*
 * @Author: qxy
 * @Date: 2020-03-27 14:12:13
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-10-Mo 08:19:50
 */

import reachShow from 'ssr-common/vue/directive/reach-show';

import {BRAND} from 'credmex-new/common/constant/config';

import generateEvent from 'easycash/common/directive/event';

import eventV2 from './event-v2';

const event = generateEvent(BRAND);

export default {
    install(Vue) {
        Vue.directive('yqg-event', event);
        Vue.directive('reach-show', reachShow);
        Vue.directive('event', eventV2);
    }
};
