/*
 * @Author: xingpan
 * @Date: 2024-03-14 10:28:27
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-08 17:52:03
 */
import { withScope, captureException } from '@sentry/vue';
export const apiErrorReportToSentry = function (request, response) {
    try {
        const { url, method, params, data, deviceToken, cookies } = request || {};
        const { httpCode, code, traceId, detail } = response || {};
        const isFileUploadApi = data instanceof FormData;
        withScope(scope => {
            scope.setLevel('info');
            scope.setTags({
                httpCode,
                isFileUploadApi,
                requestUrl: url,
                requestMethod: method,
                responseCode: code,
                deviceToken,
                responseTraceId: traceId,
                responseMessage: detail
            });
            scope.setExtra('hasUserToken', cookies?.includes('userToken'));
            const ckList = cookies?.split?.(';') || [];
            scope.setExtra('ckCount', ckList?.length);
            scope.setExtra('ckSize', ckList?.map?.((ck) => ck?.length));
            scope.setContext('requestParams', { params, data });
            captureException(new Error(url));
        });
    }
    catch (error) {
        // ignore
    }
};
