/**
 * @Author: xiaodongyu
 * @Date: 2023-07-28 12:08:36
 * @Last Modified by: huirubai
 * @Last Modified time: 2023-12-22 15:59:21
 */
import Vue from 'vue';
import { initSentry as initBaseSentry } from '@shared/client-next/util/sentry';
export * from '@sentry/vue';
export function initSentry({ router, throwTestError, beforeSend, ...rest }) {
    initBaseSentry({ router, throwTestError, beforeSend, Vue, ...rest });
}
