/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:02:50
 */
/* global __OCS__ */
export const OCS = __OCS__;

export const rootTitle = 'Credmex';

export const downloadKey = 'CREDMEX_DOWNLOAD_URL';

export const downloadUrl = 'https://play.google.com/store/apps/details?id=com.fintopia.mxcredmex&hl=es-419&gl=mx';

export const appleDownloadUrl = 'https://apps.apple.com/mx/app/id1624441976?l=es-mx';

export const iosMarketUrl = 'https://itunes.apple.com/app/id1624441976';

export const marketUrl = 'market://details?id=com.fintopia.mxcredmex';

export const BRAND = 'credmex';
