var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webview-loading-circle" }, [
    _c("div", { staticClass: "loading-content" }, [
      _c("svg", { staticClass: "circular", attrs: { viewBox: "0 0 50 50" } }, [
        _c("circle", {
          attrs: {
            cx: "25",
            cy: "25",
            r: "15",
            fill: "none",
            stroke: _vm.loadingColor,
            "stroke-width": "5%",
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }